import React, { useState } from 'react';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const [status, setStatus] = useState('');
  const [showModal, setShowModal] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Show success message immediately after clicking Submit
    setShowModal(true);
    setStatus('Thank you for contacting us!');

    fetch('https://formsubmit.co/ajax/admin@dataflows.com.au', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleModalClose = () => {
    setShowModal(false);

    // Scroll to the top, then reload after a short delay
    window.scrollTo(0, 0);
    setTimeout(() => {
      window.location.reload();
    }, 200); // Delay in milliseconds to allow the scroll to complete
  };

  return (
    <div style={{ display: 'flex', position: 'relative', alignItems: 'center', flexDirection: 'column', justifyContent: 'center', minHeight: '100vh' }}>
      <h1 style={{ marginBottom: '20px', fontSize: '24px' }}>Contact Us</h1>
      <form
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '10px',
          width: '100%',
          maxWidth: '500px',
        }}
        onSubmit={handleSubmit}
      >
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '100%' }}>
          <label htmlFor="name">Full Name</label>
          <input
            type="text"
            name="name"
            style={{ padding: '10px', borderRadius: '5px', border: '1px solid #ccc', width: '100%' }}
            placeholder="Full Name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '100%' }}>
          <label htmlFor="email">Email Address</label>
          <input
            type="email"
            name="email"
            style={{ padding: '10px', borderRadius: '5px', border: '1px solid #ccc', width: '100%' }}
            placeholder="Email Address"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '100%' }}>
          <label htmlFor="message">Your Message</label>
          <textarea
            placeholder="Your Message"
            style={{
              padding: '10px',
              borderRadius: '5px',
              border: '1px solid #ccc',
              width: '100%',
              minHeight: '100px',
            }}
            name="message"
            rows="10"
            value={formData.message}
            onChange={handleChange}
            required
          ></textarea>
        </div>
        <div style={{ alignSelf: 'center', marginTop: '10px' }}>
          <button
            type="submit"
            style={{
              padding: '10px 20px',
              backgroundColor: '#000',
              color: '#fff',
              border: 'none',
              borderRadius: '5px',
              cursor: 'pointer',
            }}
          >
            Submit Form
          </button>
        </div>
      </form>

      {showModal && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              backgroundColor: '#fff',
              padding: '20px',
              borderRadius: '8px',
              boxShadow: '0 2px 10px rgba(0, 0, 0, 0.2)',
              textAlign: 'center',
              maxWidth: '400px',
              width: '100%',
            }}
          >
            <p>{status}</p>
            <button
              onClick={handleModalClose}
              style={{
                marginTop: '15px',
                padding: '10px 20px',
                backgroundColor: '#000',
                color: '#fff',
                border: 'none',
                borderRadius: '5px',
                cursor: 'pointer',
              }}
            >
              OK
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ContactForm;

