import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'

import Script from 'dangerous-html/react'
import { Helmet } from 'react-helmet'

import ContactForm4 from '../components/contact-form4'
import './terms-of-use.css'

const TermsOfUse = (props) => {
  return (
    <div className="terms-of-use-container1">
      <Helmet>
        <title>Terms-of-use - Active Online Software Page</title>
        <meta
          property="og:title"
          content="Terms-of-use - Active Online Software Page"
        />
      </Helmet>
      <section className="terms-of-use-hero">
        <header className="terms-of-use-header">
          <div id="mobile-menu" className="terms-of-use-mobile-navigation">
            <img
              alt="pastedImage"
              src="/pastedimage-no9b-1500h.png"
              className="terms-of-use-logo1"
            />
            <div className="terms-of-use-links1">
              <span className="Link">Solutions</span>
              <span className="Link">How it works</span>
              <span className="Link">Prices</span>
            </div>
            <div
              id="close-mobile-menu"
              className="terms-of-use-close-mobile-menu"
            >
              <svg
                viewBox="0 0 804.5714285714286 1024"
                className="terms-of-use-icon1"
              >
                <path d="M741.714 755.429c0 14.286-5.714 28.571-16 38.857l-77.714 77.714c-10.286 10.286-24.571 16-38.857 16s-28.571-5.714-38.857-16l-168-168-168 168c-10.286 10.286-24.571 16-38.857 16s-28.571-5.714-38.857-16l-77.714-77.714c-10.286-10.286-16-24.571-16-38.857s5.714-28.571 16-38.857l168-168-168-168c-10.286-10.286-16-24.571-16-38.857s5.714-28.571 16-38.857l77.714-77.714c10.286-10.286 24.571-16 38.857-16s28.571 5.714 38.857 16l168 168 168-168c10.286-10.286 24.571-16 38.857-16s28.571 5.714 38.857 16l77.714 77.714c10.286 10.286 16 24.571 16 38.857s-5.714 28.571-16 38.857l-168 168 168 168c10.286 10.286 16 24.571 16 38.857z"></path>
              </svg>
            </div>
          </div>
          <div className="terms-of-use-desktop-navigation">
            <nav className="terms-of-use-centered">
              <div className="terms-of-use-left1">
                <Link to="/" className="terms-of-use-navlink1">
                  <img
                    alt="pastedImage"
                    src="/dataflows-high-resolution-logo-200h.png"
                    className="terms-of-use-logo2"
                  />
                </Link>
                <div className="terms-of-use-links2">
                  <span className="Link">Features</span>
                  <span className="Link">How it works</span>
                  <span className="Link">Prices</span>
                </div>
              </div>
              <div className="terms-of-use-right1">
                <span className="terms-of-use-sign-in Link">Sign in</span>
                <div className="terms-of-use-get-started">
                  <span className="terms-of-use-text16">Get started</span>
                </div>
                <div id="open-mobile-menu" className="terms-of-use-burger-menu">
                  <img
                    alt="pastedImage"
                    src="/pastedimage-yxbd.svg"
                    className="terms-of-use-mobile-menu-button"
                  />
                </div>
              </div>
            </nav>
          </div>
          <div>
            <div className="terms-of-use-container3">
              <Script
                html={`<script defer>
/*
Mobile menu - Code Embed
*/

// Mobile menu
const mobileMenu = document.querySelector("#mobile-menu")

// Buttons
const closeButton = document.querySelector("#close-mobile-menu")
const openButton = document.querySelector("#open-mobile-menu")

// On openButton click, set the mobileMenu position left to -100vw
openButton.addEventListener("click", function() {
    mobileMenu.style.transform = "translateX(0%)"
})

// On closeButton click, set the mobileMenu position to 0vw
closeButton.addEventListener("click", function() {
    mobileMenu.style.transform = "translateX(100%)"
})
</script>`}
              ></Script>
            </div>
          </div>
        </header>
        <header className="terms-of-use-content">
          <h1 className="terms-of-use-text17">
            <span className="terms-of-use-text18">Terms of use</span>
            <br className="terms-of-use-text19"></br>
            <br></br>
          </h1>
        </header>
        <span className="terms-of-use-text21">
          <span>Last Updated: 01/01/2024</span>
          <br></br>
          <br></br>
          <br></br>
          <span>
            {' '}
            Welcome to DataFlows Australia (&quot;Service&quot;). By accessing
            or using our web application, you agree to comply with and be bound
            by these Terms of Use.
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
          <br></br>
          <br></br>
          <span>If you do not agree, please do not use our Service.</span>
          <br></br>
          <br></br>
          <span>
            {' '}
            1. Acceptance of Terms By using this Service, you affirm that you
            are at least 18 years old or have obtained parental consent.
          </span>
          <br></br>
          <br></br>
          <span>
            {' '}
            2. Services Provided DataFlows Australia provides a platform for
            sending and receiving SMS messages. You are responsible for the
            content of your messages and ensuring compliance with applicable
            laws.
          </span>
          <br></br>
          <br></br>
          <span>
            {' '}
            3. User Responsibilities You agree not to: Use the Service for any
            unlawful purposes. Send unsolicited messages (spam) or engage in
            harassment. Impersonate any person or entity. Violate any local,
            state, or national laws.
          </span>
          <br></br>
          <br></br>
          <span>
            {' '}
            4. Account Security You are responsible for maintaining the
            confidentiality of your account information and for all activities
            that occur under your account. Notify us immediately of any
            unauthorized use of your account.
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
          <br></br>
          <br></br>
          <span>
            {' '}
            5. Fees and Payments Usage of the Service may incur fees, as
            outlined in the pricing section of our website. Payments are
            non-refundable.
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
          <br></br>
          <br></br>
          <span>
            {' '}
            6. Termination We reserve the right to suspend or terminate your
            access to the Service at our discretion, without notice, for conduct
            that we believe violates these Terms or is harmful to other users or
            the Service.
          </span>
          <br></br>
          <br></br>
          <span>
            {' '}
            7. Intellectual Property All content, features, and functionality of
            the Service, including but not limited to text, graphics, logos, and
            software, are the exclusive property of DataFlows Australia and are
            protected by copyright and other intellectual property laws.
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
          <br></br>
          <br></br>
          <span>
            {' '}
            8. Limitation of Liability To the fullest extent permitted by law,
            DataFlows Australia shall not be liable for any indirect,
            incidental, or consequential damages arising out of or in connection
            with your use of the Service.
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
          <br></br>
          <br></br>
          <span>
            {' '}
            9. Indemnification You agree to indemnify and hold DataFlows
            Australia, its affiliates, and their respective officers, directors,
            and employees harmless from any claims, losses, liabilities,
            damages, or expenses (including legal fees) arising from your use of
            the Service or violation of these Terms.
          </span>
          <br></br>
          <br></br>
          <span>
            {' '}
            10. Changes to Terms We reserve the right to modify these Terms at
            any time. We will notify you of changes by posting the new Terms on
            our website. Your continued use of the Service after any changes
            constitutes your acceptance of the new Terms.
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
          <br></br>
          <br></br>
          <span>
            {' '}
            11. Governing Law These Terms shall be governed by and construed in
            accordance with the laws of Australia. Any disputes arising from
            these Terms will be subject to the exclusive jurisdiction of the
            courts of [Your State].
          </span>
          <br></br>
          <br></br>
          <span>
            {' '}
            12. Contact Us If you have any questions about these Terms, please
            contact us at admin@dataflows.com.au
          </span>
        </span>
      </section>
      <ContactForm4
        action={
          <Fragment>
            <span className="terms-of-use-text66 thq-body-small">Submit</span>
          </Fragment>
        }
        content1={
          <Fragment>
            <span className="terms-of-use-text67 thq-body-small">
              <span>Contact with us now for further assistance </span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
            </span>
          </Fragment>
        }
        content2={
          <Fragment>
            <span className="terms-of-use-text70 thq-body-small">
              Get in touch with us
            </span>
          </Fragment>
        }
        heading1={
          <Fragment>
            <span className="terms-of-use-text71 thq-heading-2">
              Contact us
            </span>
          </Fragment>
        }
      ></ContactForm4>
      <footer className="terms-of-use-footer">
        <div className="terms-of-use-top">
          <div className="terms-of-use-left2">
            <div className="terms-of-use-contact">
              <span className="terms-of-use-text72">Contact</span>
              <a
                href="mailto:use@active-app.com?subject=Support"
                className="terms-of-use-link"
              >
                sales@dataflows.com.au
              </a>
            </div>
          </div>
          <div className="terms-of-use-right2">
            <div className="terms-of-use-category1">
              <span className="terms-of-use-text73">Solutions</span>
              <div className="terms-of-use-links3">
                <span className="terms-of-use-text74">SMPP Solutions</span>
                <span className="terms-of-use-text75">SMS API</span>
                <span className="terms-of-use-text76">
                  Complex Integrations
                </span>
                <span className="terms-of-use-text77">Encrypted Messaging</span>
                <span className="terms-of-use-text78">Automation</span>
              </div>
            </div>
            <div className="terms-of-use-category2">
              <span className="terms-of-use-text79">Company</span>
              <div className="terms-of-use-links4">
                <span className="terms-of-use-text80">About</span>
                <span className="terms-of-use-text81">Reseller</span>
                <Link to="/terms-of-use" className="terms-of-use-navlink2">
                  Terms of use
                </Link>
                <span className="terms-of-use-text82">Privacy Policy</span>
              </div>
            </div>
            <div className="terms-of-use-category3"></div>
          </div>
        </div>
        <div className="terms-of-use-bottom">
          <img
            alt="pastedImage"
            src="/dataflows-high-resolution-logo-200h.png"
            className="terms-of-use-branding"
          />
          <span className="terms-of-use-text83">Copyright © Active - 2024</span>
        </div>
      </footer>
    </div>
  )
}

export default TermsOfUse
